// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var BlogPost = "d_j";
export var head = "d_k";
export var head__inner = "d_l";
export var date = "d_m";
export var heading = "d_g";
export var heading__textShort = "d_n";
export var heading__textLong = "d_p";
export var intro = "d_q";
export var aniSpin = "d_c";